import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Menu from "../components/menu";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <header className="main-heading">
        <h1>
          <AniLink fade to="/">{title}</AniLink>
        </h1>
      </header>
    )
  } else {
    header = (
      <header className="global-header">
        <AniLink className="header-link-home" fade to="/">
        {title}
        </AniLink>
      </header>
    )
  }

  return (
    <div data-is-root-path={isRootPath} id="outer-container">
      <Menu right noOverlay />
      {header}
      <main>{children}</main>
      <footer className="global-footer">
        © {new Date().getFullYear()} {title}
      </footer>
    </div>
  )
}

export default Layout
